html {
    scroll-behavior: smooth;
}


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.head-nav {
    position: fixed;
    width: 100%;
    height: 1%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F0F0F0;
    text-decoration: none;

    z-index: 2;
    padding: 3vh 3%;
    box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.25);
    background-size: 100%;

}

.navBar {
    display: flex;
    align-items: center;

    top:1vh;
    list-style: none;
}

.itemNavbar{
    margin: 1vh ;
    padding: 1vh 3vh;
    font-size: 1.7vh;
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    border-radius: 30px;
    border:2px solid transparent;
    transition: all 0.2s;
}

.logo{
    display: flex;
    align-items: center;
    margin: 13vh ;
    padding: 1vh 3vh;
    width: 17.5vh;
}


.menu{display: none;}

.itemNavbar:hover{
    border-radius: 30px;
    color: #516DF1;
    border:2px solid #516DF1;
}

.itemNavbar:active{
    background-color: #516DF1;
    color: rgb(255, 255, 255);
}

@media (max-width: 769px){

    .menu{
        display: flex;
        align-items: center;
        margin: 0vh ;
        padding: 1vh 3vh;
        width: 11.5vh;
    }

    .logo{
        display: flex;
        align-items: center;
        margin: 0vh ;

    }

    .navBar {

        border-radius: 0px;
        position: absolute;
        top:100%;
        right: 0%;
        left:0%;
        align-content: center;
        text-align: center;
        
        box-shadow: none;
        flex-direction: column;
        background-color: #F0F0F0;
    }
    
    .itemNavbar{
        margin: 0;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        width: 40vh;
        height: 7vh;  
        margin-top: 3vh;
        margin-bottom: 1.5vh;
        font-size: 2.5vh;
    }
}
