.cont-form{
    height: 120vh;
    background-color:#5772EE;
    display: flex;
    justify-content: center;
}

#form-title{
    color: aliceblue;
    font-size: 3vh;
    margin-bottom: 1vh;
}

.form{
    margin-top: 15vh;
    margin-bottom: 10vh;
    width: fit-content;
    color: white;
    font-family: 'Poppins', sans-serif;
}


.juntos{
    display: flex;
    margin-top: 4vh;
    flex-direction: row;
}

.juntos input{
    width:100%;
    padding: 1vh;
    border: white;
    border-radius: 1.5vh;
    color: #000000a0;
    font-family: 'Poppins', sans-serif;
    outline: none;
}


.otros{
    margin-top: 4vh;
    width: 62vh;
}

.otros input{
    width: 100%;
    padding: 1vh;
    border: white;
    border-radius: 1.5vh;
    color: #000000a0;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

.otros textarea{
    width: 100%;
    min-width: 62vh;
    max-width:62vh;
    resize: none;
    max-lines: 1;
    height: 27vh;
    padding: 1vh;
    border: white;
    border-radius: 1.5vh;
    color: #000000a0;
    outline: none;
    font-family: 'Poppins', sans-serif;
}

textarea::-webkit-scrollbar{
    display: none;
}

.cantidad {
    margin-top: -4vh;
    color:#798ff1;
    background-color: white;
    text-align: end;
    border-bottom-left-radius:  1.5vh;
    border-bottom-right-radius:  1.5vh;
}

.cantidad p{
    margin-right: 1vh;
    padding-bottom: 1vh;
    font-size: 2vh;
}

.p1 {
    width: 30vh;
    margin-right: 2vh;
    margin-left: 0vh;
}

.p2 {
    width: 30vh;
}

.seleccion{
    align-self: flex-end;
    outline: none;
}

.seleccion select{
    width: 30vh;
    padding: 1vh;
    border: white;
    border-radius: 1.5vh;
    color: #5772EE;
    font-family: 'Poppins', sans-serif;
    outline: none;
}

.end-form {
    margin-top: 4vh;
    display: flex;
    justify-content: center;
}

.end-form button{
    width: 100%;
    padding: 1.2vh;
    color: #5772EE;
    font-family: 'Poppins', sans-serif;
    font-size: 2vh;
    background-color: white;
    border: white solid;
    border-radius: 1.5vh;
    transition: all 0.2s;
}

.end-form button:hover{
    background-color: #5772EE;
    border: white solid;
    color: white;
}

.end-form button:active{
    background-color: #3e58c9;
    border: white solid;
    color: white;
}

@media (max-width:  426px) {

    



    .form{
        width: fit-content;
        justify-content: center;
    }
    .juntos{
        width: fit-content;
        margin-top: 2vh;
    }
    .otros{
        width: 52vh;
    }
    .otros input{
        padding: 0.5vh;
    }
    .otros textarea{
        padding: 0.5vh;
    }

    .p1 {
        width: 25vh;
    }

    .p1 input {
        padding: 0.5vh;
    }
    

    .p2 {
        width: fit-content;
    }

    .p2 input {
        width: 25vh;
        padding: 0.5vh;
    }

    .seleccion select{
        width: 25vh;
        padding: 0.5vh;
    }

    .otros textarea{
        max-width: 52vh;
        min-width: 52vh;
        padding: 0.5vh;
    }

    .end-form button{
        width: 52vh;
        padding: 0.6vh;
    }
}

@media (max-width:  386px) {
    .form{
        width: fit-content;
        justify-content: center;
        font-size: 1.85vh;
    }
    .juntos{
        width: fit-content;
    }
    .otros{
        margin-top: 2vh;
        width: 37vh;
    }
    .otros input{
        padding: 0.25vh;
    }
    .otros textarea{
        padding: 0.25vh;
    }

    .p1 {
        width: 17.5vh;

    }

    .p1 input {
        padding: 0.25vh;
    }
    

    .p2 {
        width: fit-content;

    }

    .p2 input {
        width: 17.5vh;
        padding: 0.25vh;
    }

    .seleccion select{
        width: 17.5vh;
        padding: 0.25vh;
    }

    .otros textarea{
        max-width: 37vh;
        min-width: 37vh;
        padding: 0.25vh;


    }

    .end-form button{
        width: 37vh;
        padding: 0.3vh;
    }
}