.gallery-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.gallery-container img{
    width: 390px;
    height: 300px;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 7px 7px 0px #97969642;
    cursor: pointer;
    margin: 10px;
    transition: 400ms;
}

.gallery-container img:hover{
    filter: brightness(1.2);
    transform: (scale(1.03));
}