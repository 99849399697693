.container-all-e {
    margin-top: 1vh;
    display: flex;
    width: 100%;
    height: 20vh;
    background-repeat: no-repeat;
    background-position: right top;
    background-position-y: -30vh;
    background-size: 110vh;
    justify-content: center;
    
}
@media (max-width: 800px){
    .container-all-e {
        margin-top: 250px;
        display: flex;
        width: 100%;
        height: 40vh;
        background-repeat: no-repeat;
        background-position: right top;
        background-position-y: -30vh;
        background-size: 110vh;
        justify-content: end;
        
    }
}

.container-e {
    width: 90vw;
    text-align: center;
    
    max-height: 200px;
}

.text-e {
    margin-top: 1vh;
    width: 100%;
}

.text-e h1{
    color: #5772EE;
    font-family: 'Poppins', sans-serif;
    font-size: 6vh;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1vh;
    text-align: center;
}

.text-e p{
    margin-top: 2vh;
    color: #5772EE;
    font-family: 'Poppins', sans-serif;
    font-size: 2vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15vh;
    margin-bottom: 1vh;
    
    
}   
.text-e #texto-1{
    
    
}  





@media (max-width: 426px) {
    .text-e h1{
        font-size: 4vh;
    }

    .text-e p{
        font-size: 1.5vh;
    }
}

