@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:wght@800&family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:wght@800&family=Poppins:wght@400;600&family=Roboto:wght@900&display=swap');



#services-title {
    text-align: center;
    color: #516DF1;
    font-family: Roboto;
    font-size: 9.24vh;
    font-style: italic;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.749px;
    margin: 0px;
    position: relative;
    top: 40px;
}

.holder-card{
    margin-top: 50px;
}

#services-underText {
    color: rgb(0, 0, 0);
    text-align: center;
    font-family: Poppins;
    font-size: 2vh; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.54px;
    margin-bottom: 35px;
    margin-top: 60px;
    margin-left: 6px; 
}

#services-underText2{
    color: #516DF1;
    text-align: center;
    font-family: Poppins;
    font-size: 2vh; 
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.54px;
    margin-bottom: 10px;
}
#services-underText3{
    color: #516DF1;
    text-align: center;
    font-family: Poppins;
    font-size: 2vh; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.54px;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-left: 6px; 
}
@media (max-width: 800px){
    #services-underText3{
        color: #516DF1;
        text-align: center;
        font-family: Poppins;
        font-size: 2vh; 
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.54px;
        margin-bottom: 245px;
        margin-top: 60px;
        margin-left: 6px; 
    }
}

#services-proyect{
    width: 100%;
    height: 235px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

#column-box {
    width: 100vw;
    display: grid;
    justify-content: center;
    align-items: center; /* Centrar verticalmente los elementos */

    grid-template-rows: auto; /* Ajustar según las necesidades */
     /* Espacio entre filas y columnas (opcional) */
    align-content: center;
    margin-bottom: 15vh;
  }

#column-box2 {
    width: vw;
    display: grid;
    justify-content: center;
    align-items: center; /* Centrar verticalmente los elementos */
    max-height: 300px;
    grid-template-rows: auto; /* Ajustar según las necesidades */
     /* Espacio entre filas y columnas (opcional) */
    align-content: center;
    margin-bottom: 15vh;
  }

  
  #container-firts-cards {

    max-width: 86vw;
    min-width: 370px;
    min-height: 100px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #container-seconds-cards {
    
    align-content: center;
    width: 23vw;
    min-width: 265px;
    height: 300px;
    min-height: 280px;
    grid-row: 1; /* Coloca este contenedor en la primera fila */
    justify-self: center; /* Centrar horizontalmente este contenedor en su fila */
  }


  @media (max-width: 940px){
    #text-e2{
        margin-bottom: 10vw;
    }
  }

  @media (max-width: 650px){
    #text-e2{
        margin-bottom: 35vw;
    }
  }
  @media (max-width: 425px){
    #text-e2{
        margin-bottom: 60vw;
    }
  }

  @media (max-width: 330px){
    #text-e2{
        margin-bottom: 60vw;
    }
  }

  @media (max-width: 375px){
    #text-e2{
        margin-bottom: 60vw;
    }
  }

  #root{
    width: 100vw;
  }