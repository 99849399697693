.desc-container{
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-repeat: no-repeat;
    background-position: right;
    background-position-x: 50vh;
    background-size: cover;
    width: 100%;

    transition: all 1s;
}


.desc{
    display: flex;
    flex-direction: column;
    width: 90vh;
    margin-left: 5vh;
    text-align: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.desc img{
    width: 40vh;
    justify-content: center;
    margin-bottom: 4.5vh;
}

.desc h1{
    margin-bottom: 4vh;
    color:#515151;
    font-size: 2.5vh;
    font-weight: 400;
}

.desc p{
    margin-bottom: 5.5vh;
    font-size: 2.0vh;
    color: #909090;
}

.desc a{
    padding: 15px;
    padding-left: 70px;
    padding-right: 70px;
    width: 30;
    height: 7vh;
    right: 0;
    font-family: 'Poppins', sans-serif;
    background: transparent;
    border: solid 0.25vh;
    border-radius: 2vh;
    border-color: #516DF1;
    color: #516DF1;
    text-decoration: none;
    transition: all 0.3s;
}

.desc a:hover{
    background-color: #516DF1;
    color: aliceblue;
}

.desc a:active{
    background-color: #4056c1;
    color: aliceblue;
}

@media (max-width: 769px) {
    .desc-container{
        display: flex;
        overflow: hidden;
        justify-content: center;
        background-position-y: -25vh;
        background-position-x: -4vh;
        transition: all 1s;
    }
    
    
    .desc{
        display: flex;
        margin: 0;
        width: 90%;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-family: 'Poppins', sans-serif;
    }

    .desc img{
        margin-bottom: 15vh;
    }

    .desc h1{
        margin-bottom: 4vh;
        color: #505050;
        font-size: 2.5vh;
        font-weight: 400;
    }
    
    .desc p{
        margin-bottom: 5.5vh;
        font-size: 2.0vh;
        color: #676767;
    }
}

@media (max-width: 426px) {

    .desc{
        display: flex;
        margin: 0;
        width: 90%;
        flex-direction: column;
        text-align: center;
        align-items: center;
        font-family: 'Poppins', sans-serif;
    }
    .desc img{
        margin-bottom: 12vh;
    }

    .desc h1{
        font-size: 2.5vh;
        font-weight: 400;
        color: #505050;
    }
    
    .desc p{
        margin-bottom: 9.0vh;
        font-size: 1.8vh;
        color: #676767;
    }
}

@media (max-width: 376px) {
    .desc img{
        margin-bottom: 8vh;
    }
    .desc p{
        text-align: justify;
        margin-bottom: 8vh;
    }
}

@media (max-width: 321px) {
    .desc img{
        margin-bottom: 10vh;
        width: 25vh;
    }

    .desc h1{
        font-size: 2.1vh;
        font-weight: 400;
        color: #505050;
    }
    
    .desc p{
        text-align: justify;
        margin-top: 3vh;
        margin-bottom: 10vh;
        font-size: 1.6vh;
        line-height: 2.5vh;
        color: #676767;
    }
}
