@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat:wght@800&family=Poppins:wght@400;600&display=swap');

.card-container {
    width: 160.19px;
    margin: 12.45px;
    user-select: none;
    display: grid;
    place-items: center; 
    height: 217.91px;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.25);
    transition: opacity 0.3s ease, border-radius 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    grid-template-columns: repeat(auto-fit,minmax(50px,160px));
}

.card-container-active {
    margin: 12.45px;
    display: grid;
    place-items: center;
    width: 160.19px;
    height: 217.91px;
    border-radius: 9px;
    background: #516DF1;
    box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.25);
    user-select: none;
    transition: opacity 0.3s ease, border-radius 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    
}

.card-container p {
    display: none;
    color: #cdcfdb;
    font-size: 0;
    width: 0px;
    height: 0px;
}

.card-container-active p {
    display: grid;
    color: #FFF;
    font-family: Poppins;
    font-size: 10.17px;
}

.card-container-active #card-title {
    font-size: 14.94px;
    transition: transform 0.5s ease;
    font-weight: 600;
    color: #FFF;
    margin: 10px;
    transform: translateY(-140%);
}

.card-container-active img {
    opacity: 0;
}

#card-title {
    width: 136.53px;
    height: 49.09px;
    user-select: none;
    color: #000;
    font-family: Poppins;
    font-size: 11.81px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.5%;
    letter-spacing: 0.3543px;
    
}

.card-container-active #card-text {
    transition: transform 3s ease;
    color: #FFF;
    transform: translateY(-100%); 
    
    
}

.letter-container{
    justify-content: center;
}

#card-text {
    width: 132.85px;
    height: 90.94px;
    color: #000000;
    font-family: Poppins;
    font-size: 12.19px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.5%;
    margin: auto;
    transition: transform 3s ease;
    text-align: center;
    grid-template-rows: repeat(auto-fit,minmax(140px,150px));
}

/* Card Types */

            /* Bigger */
            .card-container-active #card-text-big{
                transition: transform 3s ease;
                color: #ffffff;
                transform: translateY(-135%); 
            }
            #card-text-big{
                width: 132.85px;
                height: 90.94px;
                color: #ffffff;
                font-family: Poppins;
                font-size: 12.19px;
                font-style: normal;
                font-weight: 400;
                line-height: 125.5%;
                margin: auto;
                transition: transform 3s ease;
                text-align: center;
            }
            .card-container-active #card-title-big {
                font-size: 14.94px;
                transition: transform 0.5s ease;
                font-weight: 600;
                color: #FFF;
                margin: 10px;
                transform: translateY(-215%);
                
            }
            #card-title-big {
                width: 136.53px;
                height: 49.09px;
                user-select: none;
                color: #000;
                font-family: Poppins;
                font-size: 11.81px;
                font-style: normal;
                font-weight: 400;
                line-height: 125.5%;
                letter-spacing: 0.3543px;                
            }

            /* Medium */
            .card-container-active #card-text-medium{
                transition: transform 3s ease;
                color: #ffffff;
                transform: translateY(-55%); 
            }
            #card-text-medium{
                width: 132.85px;
                height: 90.94px;
                color: #ffffff;
                font-family: Poppins;
                font-size: 12.19px;
                font-style: normal;
                font-weight: 400;
                line-height: 125.5%;
                margin: auto;
                transition: transform 3s ease;
                text-align: center;
            }
            .card-container-active #card-title-medium {
                font-size: 14.94px;
                transition: transform 0.5s ease;
                font-weight: 600;
                color: #FFF;
                margin: 10px;
                transform: translateY(-135%);
                
            }
            #card-title-medium {
                width: 136.53px;
                height: 49.09px;
                user-select: none;
                color: #000;
                font-family: Poppins;
                font-size: 11.81px;
                font-style: normal;
                font-weight: 400;
                line-height: 125.5%;
                letter-spacing: 0.3543px;                
            }

.icon-btn {
    margin-top: 25.5px;
    width: 72.09px;
    height: 73.08px;
    user-select: none;
}

.card-father-container {
    height: 235px;
}

/* Contenedores */

.main-div {
    position: absolute;
    left: 0;
    margin-top: 500px;
    width: 100%;
    height: 100%;
}

.line-card {
    width: 80vw;
    min-width: 360px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 70px;
}
@media (max-width: 426px){
    .line-card {
        width: 80vw;
        min-width: 360px;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 70px;
        margin-left: 37px;
    }
}
@media (max-width: 375px){
    .line-card {
        width: 80vw;
        min-width: 360px;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 70px;
        margin-left: 15px;
    }
}

.services {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.line-card .card-father-container .card-container{
    margin: 0px;
}

.line-card .card-father-container .card-container-active{
    margin: 0px;
}

#text-e2{
    margin-top: 40px;
}