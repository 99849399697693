

.container-all {
    margin-top: 1vh;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #5772EE;
    background-image: url("../img/chile.svg");
    background-repeat: no-repeat;
    background-position: right top;
    background-position-y: -30vh;
    background-size: 110vh;
}

.container {
    width: 60%;
}

.text {
    margin-top: 9vh;
    margin-left: 5.5vh;
    width: 100%;
}

.text h1{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 6vh;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1vh;
}

.text p{
    margin-top: 2vh;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 2vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15vh;
    margin-bottom: 1vh;
}   

.bubble-container {
    margin-top: 4vh;
    width: fit-content;
}

.bubble {
    background-color: #FFF;
    width: 20vh;
    text-align: center;
    margin-top: 2.4vh;
    padding: 0.8vh;
    border-radius: 2vh;

    color: #5772EE;
    font-family: 'Poppins', sans-serif;
    font-size: 1.7vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.69px;
}


@media (max-width: 884px) {
    .container-all {
        background-position-y: -30vh;
        background-position-x: 23vh;
        background-size: 100vh;
        margin-top: 5vh;
    }
    
    .container {
        width: 60%;
        
    }

}

@media (max-width: 650px) {
    .container-all {
        margin-top: 2vh;
    }


}

@media (max-width: 426px) {
    .container-all {
        background-image: url("../img/chile2.svg");
        background-position-x: 7vh;
        background-position-y: -5vh;
        background-size: 90vh;
    }
    
    .container {
        width: 60%;
    }
    
    .text {
        margin-top: 9vh;
        margin-left: 5.5vh;
        width: 100%;
    }
    
    .text h1{
        font-size: 5vh;
    }
    
    .text p{
        margin-top: 2vh;
        font-size: 1.5vh;
        letter-spacing: 0.15vh;
        margin-bottom: 1vh;
    }   
    
    .bubble-container {
        margin-top: 4vh;
        width: fit-content;
    }
    
    .bubble {
        background-color: #FFF;
        width: 18vh;
        margin-top: 2.4vh;
        padding: 0.8vh;

        font-size: 1.5vh;

    }
}

@media (max-width: 376px) {
    .container-all {
        background-image: url("../img/chile2.svg");
        background-position-x: 1vh;
        background-position-y: -4vh;
        background-size: 90vh;
    }  
    .text {
        margin-top: 9vh;
        margin-left: 5.5vh;
        width: 100%;
    }

}

@media (max-width: 321px) {
    .container-all {
        background-image: url("../img/chile2.svg");
        background-position-x: -4vh;
        background-position-y: -10vh;
        background-size: 90vh;
    }  
    .text {
        margin-top: 9vh;
        margin-left: 5.5vh;
        width: 100%;
    }

}

